/* fonts */
@font-face {font-family: "LatoBold";src: url("./fonts/Lato-Bold.ttf");}
@font-face{font-family: "LatoRegular";src: url("./fonts/Lato-Regular.ttf");}
@font-face{font-family: "LatoThin";src: url("./fonts/Lato-Thin.ttf");}
@font-face{font-family: "LatoItalic";src: url("./fonts/Lato-Italic.ttf");}
@font-face{font-family: "GeorgiaBold";src: url("./fonts/georgia\ bold.ttf");}
@font-face{font-family: "GeorgiaRegular";src: url("./fonts/Georgia\ Regular\ font.ttf");}
@font-face{font-family: "Georgia";src: url("./fonts/Georgia.ttf");}
@font-face{font-family: "GeorgiaItalic";src: url("./fonts/georgia\ italic.ttf");}
@font-face {font-family: "NunitoBold";src: url("./fonts/NunitoSans-Bold.ttf");}
@font-face{font-family: "NunitoRegular";src: url("./fonts/NunitoSans-Regular.ttf");}
@font-face{font-family: "NunitoThin";src: url("./fonts/NunitoSans-Light.ttf");}

/* for input type number  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* for input type number Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* general styles */
.no_hover{pointer-events: none;}
.share_btn{margin-top:0px;color:white;background-color: #BC5405;border-color: #BC5405;font-weight: 600;font-size: 16px;}
.share_btn:hover{background-color: white;border-color: #BC5405;color:#BC5405;font-weight: 600}
.share_btn:focus,#menu .signin:visited{background-color: #BC5405;border-color: #BC5405;color:white;font-weight: 600}
.active_step{ background-color: #BC5405; color: white; }
.map_dimensions{ height: 400px; width: 100%}
.sidebar_filter span{font-weight: normal;text-transform: capitalize;}
.favlogowidth{width:110px}
.sponsorlogo{height: 40px;}
.partnertext{position: absolute;height: 25px;padding:0px 5px;display: none;border:1px solid dodgerblue;background-color: white;}
.ptrlogo{height:23px;padding:0px 10px}
.ptrlogo:hover + .partnertext{display: block;}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
.modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }.inline_block{display:inline-block;width: 100%;}
.clearfix{clear: both;}
.marginLeftforOffices{margin-left: 80px;}
.textinput p{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;color: #000000;}
.textinput input{width: 254.48px;height: 48px;border: 1px solid #BDBDBD;box-sizing: border-box;outline:none}
.textinput select{width: 254.48px;height: 48px;border: 1px solid #BDBDBD;box-sizing: border-box;outline:none}
.textinput textarea{height: 137px;border: 1px solid #BDBDBD;box-sizing: border-box;outline:none}
.search-button{cursor:pointer;height:52px;width:254.48px;border-color:#12BBE5;background-color: #12BBE5;}
.search-button p{color:white;font-size: 14px;font-family: 'LatoRegular';}
.search-button:hover{border-color:#12BBE5;background-color: #12BBE5;color:white;}
.search-button:visited{border-color:#12BBE5;background-color: #12BBE5;color:white;}
.you_must h3{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;color: #000000;}
.checkbox p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.prescreen h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 25px;color: #000000;}
.question h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}

.prescreen_option span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 15px;color: #000000;}
/* .pagination{min-width: 120px;width:15%;height: 30px;border: 1px solid #E0E0E0;box-sizing: border-box;border-radius: 4px;} */
.pagination span{cursor:pointer;display: flex;align-items: center;justify-content: center;text-align: center;width: 30px;height: 30px;border: 1px solid #E0E0E0;box-sizing: border-box;font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 10px;color: #006D9C;}
.info_preview_mode{background-color: white;}
.warning{background-color: #ffcc69;height:66px}
.warning h2{font-size: 25px;font-family: 'LatoBold';font-weight: bolder;color:#dc0202 }
.warning h3{font-size: 15px;font-family: 'LatoRegular';font-weight: normal;color:#262626;text-align: center;  }
.warning img{cursor: pointer;}
/* header */
.menubar{box-shadow: 0px 2px 3px #eeeeee;position:fixed;top: 0px;z-index: 100;background-color: white;}
#menu{width:100%}
.navbar-expand-lg{padding: 0px 10px !important}
.navbar-toggler-icon{width:2.5em;height:2.0em}
.navbar-brand{height:70px;}
.navbar-collapse{padding: 0px;}
.navbar-nav{height: auto;margin-right: 12px;}
.nav-item{padding:28px 7px;height:93px;margin:0px 3px}
.active{border-bottom: 3px solid #12BBE5;}
.nav-item a{color:#333333;font-size: 19px;font-weight: 600;}
.nav-item a:hover{color:#333333;text-decoration:none;border: #eeeeee;}
#menu .signin{margin-top:0px;color:white;background-color: #BC5405;border-color: #BC5405;font-weight: 600;font-size: 16px;}
#menu .signin:hover{background-color: white;border-color: #BC5405;color:#BC5405;font-weight: 600}
#menu .signin:focus,#menu .signin:visited{background-color: #BC5405;border-color: #BC5405;color:white;font-weight: 600}
.footer{background-color: #F3F3F3;}
.copyright h3{font-size: 14px;font-family: 'LatoRegular';font-weight: 400;color:#828282}
/* home page */
.condition_input input{border:none;outline: none;width: 100%;height: 58px;font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;line-height: 140%;color: #BDBDBD;}
.condition_input span{border:none}
/* loading */
.loading h3{font-size:14px ;font-style: normal;font-weight: bold;font-family: 'LatoRegular';color:#000000}
/* DataNotFound */
.datanotfound h3{font-size:18px ;font-style: normal;font-weight: bold;font-family: 'LatoRegular';color:#000000}
/* trial-search */
/* .suggestion-box{position: absolute;top:173px;left:31px; background-color: white;border: 1px solid black;}
.selected_suggestion{background-color: rgb(243 243 244 / 1.75);}
.suggestion-box p{color: black; font-size: 18px;}
.suggestion-box p:hover{color: black; font-size: 18px;background-color: rgb(243 243 244 / 1.75);} */
.suggestion-box{z-index: 20;position: absolute;top:50px;left:5px; background-color: white;border: 1px solid black;}
.selected_suggestion{background-color: rgb(243 243 244 / 1.75);}
.suggestion-box p{color: black; font-size: 18px;}
.suggestion-box p:hover{color: black; font-size: 18px;background-color: rgb(243 243 244 / 1.75);}

.trial_search{background-image:url(./images/search_main_page/Hero-modified.png);background-color: white;height:525px;margin:40px 0px;background-repeat: no-repeat;
    background-size: 100% 100%;background-position: center;}
.row{margin:0px}
.find-trial-left{height: 420px;background-color: white;padding: 10px;border-radius: 4px;margin-left:83px}
.enter_condition{margin: 10px 0px 10px 23px;width: 86%;float: left;height: 58px;padding: 0px 10px;border: 1px solid black;border-radius: 5px;}
.enter_condition input{ float: left;width: 80%;margin: 5px;font-size: 18px;height: auto;outline: none;text-decoration: none;border: none;}
.select_country{ width: 68%; height: 58px}
.select_country select{width: 81%;appearance: none;outline: none;border: none;}
.enter_zipcode{width: 68%;float: left;height: 58px;padding: 10px 8px;border: 1px solid black;border-radius: 5px;}
.enter_zipcode input{float:left;width:70%;margin:5px;font-size:18px;height:auto;outline:none;text-decoration:none;border:none;appearance:none}
.find_my_trial{display: flex;align-items: center;height: 68px;width: 55%;text-align: center;justify-content: center;background-color: #76D6EE;margin: 0px 30px 10px 92px;font-size: 20px;cursor: pointer;border-radius: 3px;}
.trial_searchicon{margin:75px 0px 0px 30px;}
.search_right{margin:100px 0px 0px 0px}
.options{cursor:pointer;display:flex;height: 60px;background-color: #eeeeee;border-radius: 10px;padding:10px 10px;justify-content: space-between;margin: 15px 0px;}
.pop_text{font-size:13px;font-family: 'LatoBold';color:black;font-weight: bolder;}
.option .icon_bg{height:43px;float:left;border-radius:10px}
.option p{padding:7px 10px;font-family:'LatoBold';font-size:18px;color:black}
.mapdetails{position:relative;top:-110px;left:0px;right:0px;bottom:0px;background-image: url(./images/search_main_page/DottedMapImg.png);background-color: transparent;width:100%;height:450px;background-position: center;background-repeat: no-repeat;}
.reviews{height:330px;position: relative;top:-136px;left:0px;right:0px;bottom:0px}
.reviews h3{font-size:14px;font-family:'LatoBold';font-weight:bolder;color:#095E73}
.reviews h2{font-size:26px;font-family:'GeorgiaBold';font-weight:bolder;color:black}
.reviews .feedback .feedback_left{width:43%}
.reviews .feedback .feedback_left img{height:280px;width: 373px;}
.reviews .feedback .feedback_right h3{padding:0px;margin:8px 0px;line-height:24px;font-size:18px;font-family:'LatoRegular';font-weight:normal;color:black}
.reviews .feedback .feedback_right h2{padding:0px;margin:0px 0px;line-height:24px;font-size:18px;font-family:'LatoRegular';font-weight:normal;color:black}
.reviews .feedback .feedback_right p{padding:0px;margin:0px 0px;font-size:18px;font-family:'LatoRegular';font-weight:400;color:#585858}
.aboutus_left{position:relative; z-index: 10; top:0px;left:120px}
.aboutus_right{background-image: url(./images/search_main_page/Union.png);height:500px;background-repeat: no-repeat;background-position: right;background-size: contain;}
.aboutus_right .blur{background-image: url(./images/search_main_page/BLUR\ 1.png);background-position: right;background-repeat: no-repeat;background-size: contain;}
.aboutus_right .blur img{height: 400px;margin:9% 0px 0px 47%}
.partners{height: 360px;}
.partners h3{font-size: 26px;font-family: 'GeorgiaBold';color:#000000;font-weight: 700px;}
.partners p{font-size: 18px;font-family: 'LatoRegular';color:#000000;font-weight: 400px;}
.partners .partner_img{height:60px;width:210px}
.partnering{background-color: #F3F3F3;margin-top: 8%;}
.partnering .positioning{position: relative;top:-100px;z-index: 10;}
.partnering-right{background-color: #FCF2E9;}
.partnering-right h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;color: #006D9C;}
.partnering-right p{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 26px;color: #000000;}
.partnering-right .get-in-touch{display: flex;align-items: center;justify-content:flex-end;text-align: center;letter-spacing: 0.04em;text-transform: uppercase;}
.partnering-right .get-in-touch a{font-size:16px;font-weight: 500;font-family: 'LatoRegular';background-color: #C86007;}
.partnering-right .get-in-touch:hover{border-color: #C86007;}
.partnering-right .get-in-touch:visited{background-color: #C86007;border-color: #C86007;}
/* sidebar */
.zipcode-map{height:48px; width:258.48px;border:1px solid #BDBDBD}
.zipcode-map img{padding:0px 10px}
/* results page */
.pt_advertise_img{height: 25px;width: 25px;}
.pt_advertise_span{font-style: normal;font-size: 14px;font-family: 'LatoRegular';font-weight: normal;color:#000000}
.screenflow h2,span{font-size: 14px;font-style: normal;font-family: 'LatoRegular';color: #000000;font-weight: bold;}
.results .results_right h2{font-style: normal;font-size: 14px;font-family: 'LatoRegular';font-weight: normal;color:#000000}
.results .results_right h5{font-style: normal;font-size: 18px;font-family: 'LatoRegular';font-weight: normal;color:#000000}
.results .card{height:auto;}
.results .card_description p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 14px;color:#000000}
.results .card_description h3{font-family: 'LatoRegular';font-style: normal;font-weight: 500;font-size: 18px;color:#006D9C}
.results .card_location h4{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 10px;color: #828282;}
.results .card_location h5{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;color: #000000;}
.results .card_location span{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;color:#006D9C;}
.results .card_right_border{border-color:#F3F3F3 ;}
.ptcard_bottom p{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 10px;color: #828282;}
.ptcard_bottom span{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;color:#000000;}
/* trialdetails */
.trialdetails .updated_date{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 14px;color: #828282;}
.trialdetails .title{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 26px;color: #000000;}
.trialdetails .details h3,span{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;}
.goal-sponsor h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;}
.goal-sponsor p{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;}
.details .purpose-timeframe h2,p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:#000000}
.details .purpose-timeframe p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:#000000;line-height:25.2px;}
.trialdetails .closestlocation{border:1px solid #E0E0E0;border-radius: 10px;}
.trialdetails .closestlocation h2{font-size: 14px;font-style: normal;font-weight: bold;font-family: 'LatoRegular';color:#828282}
.trialdetails .closestlocation h3{font-size: 18px;font-style: normal;font-weight: bold;font-family: 'LatoRegular';color:#000000}
.trialdetails .closestlocation .enroll{background-color: #BC5405;color:#ffffff;font-family: 'LatoRegular';font-size: 14px;font-weight: 700;font-style: normal;}
.trialdetails .closestlocation .enroll:hover{border-color: #BC5405;color:#ffffff}
.trialdetails .closestlocation .enroll:visited{background-color:#BC5405;border-color: #BC5405;color:#ffffff}
.trialdetails .closestlocation  p{color:#006D9C;text-decoration: underline;font-size: 14px;font-style:normal}
.notification h2,p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 14px;color: #828282;}
.turn-on-notification{color:#000000;font-family: 'LatoRegular';font-size: 14px;font-weight: 700;font-style: normal}
.turn-on-notification:hover{border-color: #E57D24;color:black}
.turn-on-notification:visited{background-color:transparent;border-color:#E57D24;color:black}

.next h3{color:white;font-size: 16px;font-style: normal;font-weight: 900;letter-spacing:0.04rem;font-family: 'LatoRegular';}
.next:hover{background-color: #BC5405;}
.next:visited{background-color: #BC5405;}
/* enrollment */
.enrollmentsheet_header h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;color: #000000;}
.enrollmentsheet_header a{background-color: #BC5405;color:white;font-size: 16px;font-weight: normal;}
.enrollmentsheet_header h3{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;color: #000000;}
.search_input input:focus{outline: none;}
.search_input{height: 52px;background: #FFFFFF 0% 0% no-repeat padding-box;border: 1px solid #949494;border-radius: 3px;opacity: 1;}
.search_input input{text-align: left;font-style: normal ;font-size:  16px; font-family:  "NunitoRegular";letter-spacing: 0.11px;color: black;opacity: 1;outline: none; border: none;}
.search_input p{text-align: left;font-style: normal;font-size:  11px; font-family: "NunitoThin";letter-spacing: 0.1px;color: #66676B;opacity: 1;}

/* acknowledgementform */
.modal-title{font-size: 25px;font-weight: bold;font-family: 'LatoBold';}
.acknowledgement_form h3{font-size: 25px;font-weight: bold;font-family: 'LatoBold';}
.acknowledgement_form p{font-size: 18px;font-weight: normal;font-family: 'LatoRegular';}
.acknowledgement_button{background-color: #BC5405;color:white;font-size: 16px;font-family: 'LatoRegular';}
.acknowledgement_button:hover{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.acknowledgement_button:visited{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}

/* thankyou */
.completed_steps h3{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 26px;color: #BC5405;}
.completed_steps h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 22px;color: #006D9C;}
.completed_icon{height: 120px;width: 120px;}
.checkedsteps h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 22px;color: #000000;}
.back_link_buttons{margin-left: 145px;}
.back_link_buttons h2{font-family: 'LatoRegular';font-style: normal;font-weight: 900;font-size: 16px;letter-spacing: 0.04em;text-transform: uppercase;color: #000000;}
.nextsteps h3{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 14px;text-transform: uppercase;color: #095E73;}
.nextsteps p{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 26px;color: #000000;}
.nextstep h2{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 22px;color: #000000;}
.nextstep p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.download-left{position: relative;left: 9%;z-index: 10;}
.download-left h3{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 26px;color: #000000;}
.download-left p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.download_right{background-image: url(./images/search_main_page/Union.png);height:500px;background-repeat: no-repeat;background-position: right;background-size: contain;}
.download_right .blur{background-image: url(./images/search_main_page/BLUR\ 1.png);background-position: right;background-repeat: no-repeat;background-size: contain;}
.download_right .blur img{height: 400px;margin:8% 0px 0px 47%}
.share_records_content h4{font-family: 'LatoRegular';font-style: normal;font-weight: 700;font-size: 25px;line-height: 30px;color: #000000;}
.share_records_content p{font-family: 'LatoRegular';font-style: normal;font-weight: 400;font-size: 18px;line-height: 20px;color: #000000;}

/* promo trial */
.preview_mode{border:1.5px solid white;border-radius: 5px; background-color: #f59393; color:white;}
.preview_mode .warning_icon{height:60px;width:auto}
.preview_mode p{color:white}
.preview_mode .warning_close_icon{height: 15px;cursor: pointer;}
.preview_content p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 15px;color: white;}
.vaccine{height:275px;background-color: #e8eff9;}
.vaccine .left h2{font-size: 32px;font-family: 'GeorgiaBold';color:#000000;font-weight: bold;}
.vaccine .left h3{font-size: 22px;font-family: 'LatoRegular';color:#000000;}
.vaccine .left p{font-size: 13px;font-family: 'LatoRegular';color:#f15481;}
.vaccine .right img{height: 65%;}
.novavax h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 32px;color: #000000;}
.novavax h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color: #000000;}
.novavax p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.promo_trial_details h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 32px;color: #000000;}
.promo_trial_details p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.promo_trial_details h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color: #000000;}
.promo_trial_details img{height: 50%;}
.enrollment_section{background-color: #f4f4f4;height: 350px}
.enrollment_section img{height: 80%;}
.enrollment_section h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 22px;color: #000000}
.enrollment_section h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 22px;color: #12BBE5}
.enrollment_section p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color: #000000;}
.promo_eligibility{background-image: url(./images/promo_trial//eligibil.jpg);height: 100%;background-position: center;background-repeat: no-repeat;background-size: cover;}
.promo_eligibility h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 22px;color: #000000}
.promo_eligibility h3{font-family: 'LatoRegular';font-style: normal;font-weight: 400;font-size: 18px;color: #000000}
.promo_get_started{background-color: #BC5405;color:white;font-size: 16px;font-family: 'LatoRegular';}
.promo_get_started:hover{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.promo_get_started:visited{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.commitments h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color: #000000}
.commitments h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color: #000000}
.commitments li{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;color: #000000}
.commitments span{font-family: 'LatoRegular';font-style: normal;font-weight: 400;font-size: 18px;color: #000000}
.locations h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color: #000000}
.all_locations{height: 300px;overflow-y: scroll;}
.forEmbed{ width: 83%; top: 156px; }
/* .locations ul li{cursor: pointer;} */
.promo_question h2{font-family: 'LatoRegular';font-style: bold;font-weight: bold;font-size: 18px;color: #000000}
.promo_answer{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 15px;color: #000000}
.promo_answer input{cursor: pointer;}
.promo_answer span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 15px;color: #000000}
.promo_next{background-color: #BC5405;color:white;font-size: 16px;font-family: 'LatoRegular';}
.promo_next:hover{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.promo_next:visited{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.promo_criteria{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 22px;color: #000000}
.covid_eligibility_criteria h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color: #000000}
.covid_eligibility_criteria h3{font-family: 'LatoBold';font-style: normal;font-weight: normal;font-size: 18px;color: #000000}
.covid_eligibility_criteria li{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black;}
.covid_eligibility_criteria .covid_must span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black;}
.covid_eligibility_criteria .covid_not_must span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black;}
/* cmv */
.help_cmv h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:black}
.help_cmv h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.help_cmv p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_trialdetails h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.cmv_trialdetails h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_trialdetails_right{background-image: url(./images/cmv/enrol.png);background-position: center;background-repeat: no-repeat;background-size: contain;}
.cmv_trialdetails_right p{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:black}
.cmv_eligibility{background-image: url(./images/cmv/eligibility.jpg);background-position: center;background-repeat: no-repeat;background-size: cover;}
.cmv_eligibility h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 26px;color:black}
.cmv_eligibility p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_eligibility button{background-color: #BC5405;color:white;font-size: 16px;font-family: 'LatoRegular';}
.cmv_eligibility button:hover{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.cmv_eligibility button:visited{background-color: #BC5405;font-size: 16px;font-family: 'LatoRegular'}
.what_to_expect h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 26px;color:black}
.what_to_expect h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_expectation p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_potential h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 26px;color:black}
.cmv_potential li{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_potential h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.cmv_faq h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: normal;font-size: 26px;color:black}
.cmv_faq h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.faq{cursor: pointer;}
.faq h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:black}
.faq h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.faq li{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
/* melanoma */
.melanoma h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.melanoma h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:black}
.melanoma p{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 15px;}
.melanoma_vid{background-image: url(./images/cmv/video\ bg.png);background-position: center;background-repeat: no-repeat;background-size: 100% 100%;}
.melanoma_vid img{height: 20px;}
.melanoma_vid h3{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 22px;color:black}
.melanoma_vid h4{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:#BDBDBD}
.melanoma_vid iframe{height: 350px;}
.melanoma_details h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.melanoma_details h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.melanoma_enroll{height:445px;width:auto;background-image: url(./images/cmv/enroll.jpg);background-repeat: no-repeat;background-size: contain;background-position: center;}
.melanoma_what h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.melanoma_what h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.melanoma_why h2{font-family: 'GeorgiaBold';font-style: normal;font-weight: bold;font-size: 28px;color:black}
.melanoma_why h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
/* blogs */
.populartags h2{font-family:'LatoRegular';font-style: normal;font-weight: 600;font-size: 16px;line-height: 19px;color: #000000;}
.populartags p{font-family:'LatoRegular';font-style: normal;font-weight: 600;font-size: 14px;color: #000000;}
.blog_details span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 13px;line-height: 14px;color: #000000;}
.blog_recent_details{border-right: 2px solid black;}
.blog_recent_details span{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 13px;line-height: 14px;color: #000000;}
.blog_title{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 28px;line-height: 34px;color: #000000;}
.blog_description{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 14px;line-height: 17px;color: rgba(0, 0, 0, 0.6)}
.blog_rmore{height: 35px;background: #76D6EE;border-radius: 4px;font-family: 'LatoRegular';font-style: normal;font-weight: 600;font-size: 10px;line-height: 14px;text-transform: uppercase;color: #000000;}
.recent_posts_heading{font-family: 'LatoRegular';font-style: normal;font-weight: 600;font-size: 25px;line-height: 30px;color: #000000;}
.recent_view_all{font-family: 'LatoRegular';font-style: normal;font-weight: 600;font-size: 14px;line-height: 17px;text-decoration-line: underline;color: #BC5405;}
.recent_post_title{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 12px;line-height: 14px;color: #000000;}
.select_date{outline: none;height: 41px;border: 1px solid #DADADA;box-sizing: border-box;border-radius: 2px;font-size: 12px;color:#323232;font-family: 'LatoRegular';font-style: bold;}
.search_blogs{height: 41px;border: 1px solid #DADADA;box-sizing: border-box;border-radius: 2px;}
.search_blogs input{float:left;width:70%;margin:5px;font-size:14px;height:auto;outline:none;text-decoration:none;border:none;appearance:none;color:#323232;font-family: 'LatoRegular';font-style:bold;}
.blog_main_img{
  height: 318px;
}
.blog_detail_img{height: 600px;width: 100%;}
.blog_recent_img{
  height: 240px;
}
.blog_detail_description{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 14px;line-height: 17px;color: #000000;}
.blog_detail_subtitle{font-family: 'LatoRegular';font-style: normal;font-weight: bold;font-size: 18px;line-height: 17px;color: #000000;}
.blog_faq h2{font-family: 'LatoBold';font-style: normal;font-weight: bold;font-size: 18px;color:black}
.blog_faq h3{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.blog_faq li{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;color:black}
.blog_author{border-left: 2px solid black;border-right: 2px solid black;}
.blog_border_ptags{border-left:1.5px solid black;border-right:1.5px solid black;}
.blog_promoted_title{font-family: "LatoRegular";font-weight:bolder;font-size: 28px; }
.blog_background{height: 500px; width: 100%;background-position: center;background-repeat: no-repeat;background-size: cover;}
/* upcoming study */
.upcoming_study_image{height: 425px;}
.upcoming_paid h3{font-family: "GeorgiaRegular";font-style: normal;font-weight: bold;font-size: 30px;line-height: 138%;text-transform: uppercase;color: #000000;}
.upcoming_paid p{font-family: "LatoRegular";font-style: normal;font-weight: 600;font-size: 20px;line-height: 138%;color: #000000;}
.upcoming_paid span{font-family: "LatoRegular";font-style: normal;font-weight: 600;font-size: 20px;line-height: 138%;color: #000000;}
.upcoming_signup{background-color: #BC5405;color:white;font-size: 18px;font-family: 'LatoRegular';font-weight: normal;}
.upcoming_signup:hover{background-color: #BC5405;font-size: 18px;font-family: 'LatoRegular';font-weight: normal;color:white}
.upcoming_signup:visited{background-color: #BC5405;font-size: 18px;font-family: 'LatoRegular';font-weight: normal;color:white}
.upcoming_purpose h2{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 22px;line-height: 138%;color: #000000;}
.upcoming_purpose p{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 18px;line-height: 138%;color: #000000;}
.upcoming_recent_blogs h2{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 22px;line-height: 26px;color: #000000;}
.upcoming_recent_blogs p{font-family: "LatoRegular";font-style: normal;font-weight: 600;font-size: 14px;line-height: 17px;color: #000000;}
.upcoming_recent_blogs img{height: 150px;width:245px;}
.upcoming_recent_date span{font-family: "LatoRegular";font-style: normal;font-weight: 600;font-size: 12px;line-height: 14px;color: #7E7E7E;}
.upcoming_qualify h3{font-family: "GeorgiaBold";font-style: normal;font-weight: bold;font-size: 26px;line-height: 138%;color: #000000;}
.upcoming_qualify p, .upcoming_qualify span{font-family: "LatoRegular";font-style: normal;font-weight: normal;font-size: 20px;line-height: 138%;color: #000000;}
.upcoming_criteria{font-family: "GeorgiaBold";font-style: normal;font-weight: bold;font-size: 26px;line-height: 138%;color: #000000;}

/* preview templates */
.template2_location h6, .template3_main h6{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 14px;line-height: 138%;text-transform: uppercase;color: #006D9C;}
.template2_location .site, .template3_main .site{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 30px;line-height: 140%;color: #000000;}
.template2_location p, .template3_main p{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 20px;line-height: 138%;color: #828282;}
.template2_title p{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 26px;line-height: 138%;color: #000000;}
.template2_identifier h6{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 22px;line-height: 138%;color: #12BBE5;}
.template2_identifier p{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 18px;line-height: 138%;color: #000000;}
.template2_qualify{border: 1px solid rgba(0, 0, 0, 0.2);box-sizing: border-box;border-radius: 4px;}
.template2qualify_logo{height: 70px;width: 70px;}
.template2qualify_content h6{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 18px;line-height: 138%;color: #000000;}
.template2qualify_content p{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 22px;line-height: 138%;color: #000000;}
.template2_study_number{border-right: 2px solid black;}
.template2_age_border{ border-right: 1px solid black}
.template2_age_border h6{ font-size: 18px;}
.template2_age_border p{ font-size: 22px;}
.template3_main{background: linear-gradient(180deg, rgba(118, 214, 238, 0.5) 0%, rgba(237, 239, 240, 0) 100%);}
.template3qualify_right{border: 1px solid rgba(0, 0, 0, 0.2);box-sizing: border-box;}
.template3_nctid{background: #EDEFF0;border-radius: 4px;}
.template3_nctid p{font-family: "LatoRegular";font-style: normal;font-weight: normal;font-size: 18px;line-height: 138%;color: #000000;}
.template3_nctid h6{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 19px;line-height: 138%;color: #000000;}
/* template4 */
.template4_title{font-family: 'LatoRegular';font-style: normal;font-weight: 700;font-size: 22px;line-height: 138%;color: #000000;}
.template4_subtitle{font-family: 'LatoRegular';font-style: normal;font-weight: normal;font-size: 18px;line-height: 138%;color: #000000;}

/* template5 */
.t5_title{font-family: "LatoRegular";font-style: normal;font-weight: bold;font-size: 20px;line-height: 138%;color: #000000;}
.t5_container{display: flex; align-items: center; justify-content: space-between;}
.t5_identifier:hover{border-top: 6px solid #E57D24;}

/* template6 */
.t6_main_logo{ height: 300px; position: relative; }
.t6_sub_logo{ position: relative;height: 150px;top: -60px;left: 150px}

/* chat plugin related */
.Twilio{
    font-size: 14px;
    font-weight: normal;
    color: black;
}

.Twilio-MainContainer {
  font-family: "Open Sans", sans-serif;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  }
  
  .Twilio-MainHeader {
  height: 3rem;
  }
  
  .Twilio-MainHeader-default img {
  margin: 0 15px;
  }
  
  .Twilio-WelcomeMessage-IconContainer {
  display: none;
  }
  
  .Twilio-WelcomeMessage-TextContainer {
  display: flex;
  margin: 0.25rem 0.25rem;
  text-align: left;
  font-size: 14px;
  padding: 0 0 1rem 0.5rem;
  }
  
  .Twilio-WelcomeMessage-default {
  display: flex;
  }
  
  .Twilio-MessageCanvasTray-default {
  display: flex;
  margin: 0 1rem;
  align-items: center;
  }
  
  .Twilio-MessageCanvasTray-default button {
  border-radius: 0.25rem;
  margin: 0.5rem;
  }
  
  .Twilio-InlineDateSeparator {
  display: none;
  }
  
  .Twilio-MessageBubble-Body {
  font-size: 14px;
  }
  
  .Twilio-MessagingCanvas {
  display: flex;
  }
  
  .Twilio-ChatItem-Avatar img {
  width: 25px;
  height: 25px;
  }

@media only screen and (max-width: 992px) and (min-width: 250px){
    /* header */
    .nav-item{height:55px;padding:15px 7px;}
    .navbar-brand{margin-left: 0px;}
    #menu .signin{max-width:100%;margin:10px;min-width: 40%;}
    .results .card{height: auto;}
    .results .card span{font-size: 13px;}
    .find-trial-left{margin-left:0px}
    .select_country{ width: 64%;}
    .select_country select{width: 69%;}
    .enter_zipcode{width: 64%;}
    .search_blogs{width:64%}
    .find_my_trial{width:auto}
    .aboutus_left{top:90px;left:0px}
    .aboutus_right{height: auto;margin-top: 110px;}
    .aboutus_right .blur{height: 200px;}
    .aboutus_right .blur img{margin: 20px 0px 0px 155px;height: 125px;}
    .reviews{height: auto;top:110px}
    .partners{height: auto;}
    .warning h2{font-size: 10px; height: auto;}
    .warning h3{margin:0px 0px 0px 15px;font-size: 7px; height: auto;}
    .partnering{background-color: transparent;}
    .partnering .positioning{top:0px}
    .positioning img{width:100%}
    .enrollment_section{height: auto;}
    .vaccine{height: auto;}
    .vaccine .right img{height: auto;}
    .download-left{left: 0px;}
    .download_right{height: 235px;}
    .download_right .blur img {height: 173px;margin: 8% 0px 0px 50%;}
    .cmv_eligibility{background-position: left;}
    .blog_main_img{height: 300px;}
    .blog_recent_img{height: 300px;}
    .blog_detail_img{height: 300px;width: 100%;}
    .blog_recent_details{border: none;text-align: center;padding:0px}
    .blog_author{border: none;}
    .blog_border_ptags{border: none;text-align: left;}
    .blog_background{background-position: left;}
    .upcoming_study_image{height: auto;}
    .t5_container{ display: block;}
    /* template6 */
    .t6_main_logo{ height: 200px; position: relative; }
    .t6_sub_logo{ position: relative;height: 150px;top: -60px;left: 20px}
    .forEmbed{ width: 92%; top: 255px; }
}
@media only screen and (max-width:992px) and (min-width:640px){
    
    .aboutus_right{height: 400px;}
    .aboutus_right .blur{height: 400px;}
    .aboutus_right .blur img{height: 250px;margin:43px 0px 0px 400px}
    .download_right{height: 400px;}
    .download_right .blur{height: 400px;}
    .download_right .blur img {
        height: 250px;
        margin: 8% 0px 0px 43%;
    }
    .forEmbed{ width: 80%; top: 187px; }
}
@media only screen and (min-width: 568px) and (max-width: 568px){
    /* header */
    .navbar-brand{margin:0px;width:180px}
    .find-trial-left{margin-left:0px}
    .aboutus_right .blur{height: 220px;}
    .aboutus_right .blur img{height: 178px;margin:5px 0px 0px 368px}
    .download_right .blur{height: 230px;}
    .download_right .blur img {height: 150px;margin: 11% 0px 0px 60%;}
}

@media only screen and (max-width: 540px){
  .suggestion-box{width: 400px}
  .template2_study_logo{ height: 170px;}
  .template2_study_number{border-right: 0;}
  .template2_age_border{border-right: 0;}
  .template2_age_border h6{ font-size: 11px;}
  .template2_age_border p{ font-size: 15px;}
  .template3_qualify_logo{height: 200px;}

}

@media only screen and (max-width: 420px){
  .suggestion-box{width: 300px}
}

@media only screen and (max-width: 400px){
  .suggestion-box{width: 265px}
}

@media only screen and (max-width: 320px){
    /* header */
    .navbar-brand{margin:0px;width:180px}
    .find-trial-left{margin-left:0px}
    .suggestion-box{top:0px;left: 0px;}
    .select_country{ width: 60%;}
    .select_country select{width: 64%;}
    .enter_zipcode{width: 60%;}
    .search_blogs{width:60%}
    .option p{font-size: 15px;}
    .aboutus_right .blur{height: 150px;}
    .aboutus_right .blur img{height: 105px;margin:5px 0px 0px 150px}
    .back_link_buttons{margin-left: 50px;}
    .download_right .blur{height: 200px;}
    .download_right .blur img {height: 150px;margin: 11% 0px 0px 43%;}
}
@media only screen and (max-width: 280px){
    /* header */
    .menubar{padding:1px}
    .navbar-brand{margin:0px;width:180px}
    .find-trial-left{margin-left:0px}
    .suggestion-box{top:220px;left: 35px;width:170px}
    .ptr_logo{display: block;}
    .aboutus_left{top:180px}
    .reviews{top: 200px}
    .select_country{ width: 55%;}
    .select_country select{width: 55%;}
    .enter_zipcode{width: 55%;}
    .enter_zipcode input{width:55%}
    .search_blogs{width: 55%;}
    .search_blogs input{width:55%}
    .upcoming_purpose p{ overflow-wrap: break-word;}
    .template2qualify_content p{font-size: 11px;}
    .template2_study_logo{height: 130px;}
    .upcoming_paid h3{ font-size: 20px; }
    .upcoming_study_image{height: 200px;}
    .template3_qualify_logo{height: 130px;}
    .t6_main_logo{ height: 180px; position: relative; }
    .t6_sub_logo{ position: relative;height: 130px;top: -60px;left: 10px}

}
